import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Button, Table, Modal, Form, Input, Select, DatePicker, message, TableColumnsType, Upload, } from 'antd';
import { ArrowRightOutlined, CloseOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import dayjs from 'dayjs';
import { PumpPartTrackingDetails, PumpPartTracking, TrackingRecord, MotorTripCounterRecord } from '../utils/types';
import { useAuth } from '../auth/authContext';

const { Title, Text } = Typography;
const { Option } = Select;

const formatDate = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

const formatDaysFromNow = (startDate: Date, endDate: Date) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const days = duration.asDays();
  return `${Math.round(days)} days`;
};

const PumpTrackingUpdateDetails: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const { partId } = useParams<{ partId: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [motorTripCounterForm] = Form.useForm(); // Form for the motor trip counter
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [trackingDetails, setTrackingDetails] = useState<PumpPartTrackingDetails>();
  const [loading, setLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState<TrackingRecord | null>();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isDeleteModalTrackingVisible, setIsDeleteModalTrackingVisible] = useState(false);

  useEffect(() => {
    if (token) {
      fetchTrackingDetails();
    }
  }, [token]);

  const fetchTrackingDetails = async () => {
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pump-part/${partId}`);
      const details: PumpPartTrackingDetails = response.data;
  
      setTrackingDetails(details);
  
      // Sort trackings by installation_date (descending)
      const sortedTrackingDesc = details.trackings.sort((a, b) => moment(b.installation_date).diff(moment(a.installation_date)));
      
      const newDataSource = sortedTrackingDesc.map((tracking: PumpPartTracking, index: number) => {
        const previousTracking = sortedTrackingDesc[index + 1];
        const replacedIn = previousTracking
          ? formatDaysFromNow(previousTracking.installation_date, tracking.installation_date)
          : 'N/A';
  
        return {
          key: tracking.id,
          pump: details.pump,
          installedDate: formatDate(tracking.installation_date),
          reason_for_update: tracking.reason_for_update,
          job_card_reference: tracking.job_card_reference,
          installation_date: tracking.installation_date,
          replacedIn,
          old_part_image: tracking.old_part_image, // Include image URL
        };
      });
  
      setDataSource(newDataSource);
  
      // Sort trackings by installation_date (ascending) for chart data
      const sortedTrackingAsc = details.trackings.sort((a, b) => moment(a.installation_date).diff(moment(b.installation_date)));

      // Skip the first entry for the chart data
      const newChartData = sortedTrackingAsc.slice(1).map((tracking: PumpPartTracking, index: number) => {
        const previousTracking = sortedTrackingAsc[index];
        const days = moment(tracking.installation_date).diff(moment(previousTracking.installation_date), 'days');
        return {
          name: `Entry ${tracking.installation_date}`, // Adjust entry number to match skipping the first entry
          days: days,
        };
      });
  
      setChartData(newChartData);
    } catch (error) {
      message.error('Failed to fetch tracking details');
      console.error('Failed to fetch tracking details:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleBackClick = () => {
    navigate(-1);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsDeleteModalTrackingVisible(false);
    form.resetFields();
    motorTripCounterForm.resetFields();
    setSelectedRecord(undefined);
    setImageUrl(null);
  };

  const showEditModal = (record: TrackingRecord) => {
    setSelectedRecord(record);
    const installationDate = record.installation_date ? dayjs(record.installation_date, 'YYYY-MM-DD') : null;

    form.setFieldsValue({
      reason_for_update: record.reason_for_update,
      installationDate,
      job_card_reference: record.job_card_reference,
    });
    setImageUrl(record.old_part_image);
    setIsModalVisible(true);
  };

  const showDeleteTrackingModal = (record: TrackingRecord) => {
    setSelectedRecord(record);
    setIsDeleteModalTrackingVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/pump-part/${selectedRecord!.key}`;
      await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Record deleted successfully');
      fetchTrackingDetails();
      handleCancel();
    } catch (error) {
      message.error('Failed to delete record');
      console.error('Failed to delete record:', error);
    }
  };

  const handleUploadChange = ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
  
      // Check if the file size is less than 2MB
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
        return;
      }
  
      // Store the image file in the form
      form.setFieldsValue({
        old_part_image: { file: fileList[0] },
      });
  
      // For preview purposes
      const reader = new FileReader();
      reader.onload = () => setImageUrl(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const handleUpdate = async (values) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/pump-part${selectedRecord ? `/${selectedRecord.key}/updateTrackingRecord` : ''}`;
      const method = 'post';

      const formData = new FormData();
      formData.append('part_id', partId);
      formData.append('reason_for_update', values.reason_for_update);
      formData.append('installation_date', values.installationDate.format('YYYY-MM-DD'));
      formData.append('job_card_reference', values.job_card_reference || '');

      // Append the image file if it exists
      const fileField = form.getFieldValue('old_part_image');
      if (fileField && fileField.file) {
        const file = fileField.file.originFileObj; // Get the original file object
        formData.append('old_part_image', file); // Append the file to FormData
      }

      if (!selectedRecord && trackingDetails != undefined) {
        formData.append('vendor_id', trackingDetails.pump.vendor.id.toString());
      }

      await axios({
        method: method,
        url: apiUrl,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      message.success(`${selectedRecord ? 'Updated' : 'Created'} successfully`);
      fetchTrackingDetails();
      form.resetFields();
      setImageUrl(null);
      setIsModalVisible(false);
      setSelectedRecord(undefined);
    } catch (error) {
      message.error('Failed to update');
      console.error('Failed to update:', error);
    }
  };

  const columns: TableColumnsType<TrackingRecord> = [
    {
      title: 'Vendor',
      dataIndex: ['pump', 'vendor', 'name'],
      key: 'vendor',
    },
    {
      title: 'Installed',
      dataIndex: 'installedDate',
      key: 'installedDate',
    },
    {
      title: 'Replaced in',
      dataIndex: 'replacedIn',
      key: 'replacedIn',
    },
    {
      title: 'Part Image',
      dataIndex: 'old_part_image',
      key: 'old_part_image',
      render: (text) => <img src={`https://pumppromonitorb.co.za/${text}`} alt="Part" style={{ width: '50px' }} />,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      fixed: 'right',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => showEditModal(record)}>Edit</Button>
          <Button 
            type="link" 
            danger 
            onClick={() => showDeleteTrackingModal(record)} 
          >
            Delete
          </Button>
        </>
      ),
    },
  ];


  if (loading) {
    return <div>Loading...</div>;
  }

  if (!trackingDetails) {
    return <div>No tracking details found.</div>;
  }

  const selectedTrackingDate = selectedRecord ? selectedRecord.installation_date : '';

  return (
    <div style={{ padding: '16px' }}>
      <Title level={2} style={{ color: '#21456A', marginTop: '0px' }}>Pump Tracking Part</Title>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ color: '#21456A', fontWeight: 'bold' }}>{`${trackingDetails.pump.application_name} - ${trackingDetails.part_type.name}`}</Title>
        </Col>
        <Col>
          <Button shape="circle" icon={<CloseOutlined />} onClick={handleBackClick} />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        bordered
        style={{ marginBottom: '16px' }}
      />

      <Card>
        <Title level={5}>Avg {trackingDetails.part_tracking_report ? trackingDetails.part_tracking_report.average_days_to_replace : 'N/A'} Days</Title>
        <Text>This is a chart showing the average amount of days a {trackingDetails.part_type.name} lasts</Text>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="days" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Card>

      <Row justify="space-between" style={{ marginTop: '16px' }}>
        <Col span={11}>
          <Button block onClick={handleBackClick} style={{ borderColor: '#2196F3' }}>Back</Button>
        </Col>
        <Col span={11}>
          <Button block type="primary" style={{ background: '#21456A' }} onClick={showModal}>Update</Button>
        </Col>
      </Row>

      <Modal
        title={`Update ${trackingDetails.pump.name}/${trackingDetails.part_type.name}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleUpdate}>
          <Form.Item label="Reason for update" name="reason_for_update" rules={[{ required: true, message: 'Please select a reason for update' }]}>
            <Select>
              <Option value="Initial creation">Initial Creation</Option>
              <Option value="Precaution Replacement">Precaution Replacement</Option>
              <Option value="Maintenance">Maintenance</Option>
              <Option value="Routine Replacement">Routine Replacement</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Installation date" name="installationDate" rules={[{ required: true, message: 'Please enter installation date' }]}>
            <DatePicker format='YYYY-MM-DD' />
          </Form.Item>
          <Form.Item label="Jobcard reference (Optional)" name="job_card_reference">
            <Input />
          </Form.Item>
          <Form.Item label="Old Part Image" name="old_part_image">
            <Upload
              listType="picture-card"
              showUploadList={false}
              beforeUpload={(file) => {
                const isLt2M = file.size / 1024 / 1024 < 2; // Check if the file size is less than 2MB
                if (!isLt2M) {
                  message.error('Image must be smaller than 2MB!');
                }
                return false; // Always return false to prevent automatic upload
              }}
              onChange={handleUploadChange}
              accept='image/jpeg, image/jpg, image/png'
            >
              {imageUrl ? (
                <img src={imageUrl} alt="Old Part" style={{ width: '100%' }} />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}>
              <Button block onClick={handleCancel} style={{ borderColor: '#2196F3' }}>Cancel</Button>
            </Col>
            <Col span={11}>
              <Button block type="primary" htmlType="submit" style={{ background: '#21456A' }}>Update</Button>
            </Col>
          </Row>
        </Form>
      </Modal>


      <Modal open={isDeleteModalTrackingVisible} onCancel={handleCancel} footer={null}>
        <div style={styles.modalContent}>
          <ExclamationCircleOutlined style={styles.icon} />
          <Title level={4}>Delete {selectedTrackingDate.toString()}</Title>
          <Text>This will remove all data relating to {selectedTrackingDate.toString()}. This action cannot be reversed. Deleted data cannot be recovered.</Text>
          <Row justify="space-between" style={{ marginTop: '16px' }}>
            <Col span={11}>
              <Button block onClick={handleCancel}>Cancel</Button>
            </Col>
            <Col span={11}>
              <Button block type="primary" danger onClick={handleDelete}>Delete</Button>
            </Col>
          </Row>
        </div>
      </Modal>

    </div>
  );
};

const styles = {
  modalContent: {
    textAlign: 'left' as const,
  },
  icon: {
    fontSize: '24px',
    color: '#FF4D4F',
    marginBottom: '16px',
  },
};

export default PumpTrackingUpdateDetails;
