import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form, Input, DatePicker, Row, Col, message, Typography, Select } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAuth } from '../auth/authContext';
import { MotorTripCounterRecord, BreakdownCounterRecord, PumpRecord } from '../utils/types';

const { Title, Text } = Typography;
const { Option } = Select;

const MotorTripAndBreakdownCounter: React.FC = () => {
  const { token } = useAuth();
  const [mDataSource, setMDataSource] = useState<any[]>([]);
  const [bDataSource, setBDataSource] = useState<any[]>([]); // Breakdown Counter
  const [pDataSource, setPumpDataSource] = useState<any[]>([]); // Pumps
  const [selectedMotorRecord, setSelectedMotorRecord] = useState<MotorTripCounterRecord | null>(null);
  const [selectedBreakdownRecord, setSelectedBreakdownRecord] = useState<BreakdownCounterRecord | null>(null);
  const [isMotorCreateModalVisible, setIsMotorCreateModalVisible] = useState(false); // Motor create modal visibility
  const [isMotorEditModalVisible, setIsMotorEditModalVisible] = useState(false); // Motor edit modal visibility
  const [isMotorDeleteModalVisible, setIsMotorDeleteModalVisible] = useState(false); // Motor delete modal visibility

  const [isBreakdownCreateModalVisible, setIsBreakdownCreateModalVisible] = useState(false); // Breakdown create modal visibility
  const [isBreakdownEditModalVisible, setIsBreakdownEditModalVisible] = useState(false); // Breakdown edit modal visibility
  const [isBreakdownDeleteModalVisible, setIsBreakdownDeleteModalVisible] = useState(false); // Breakdown delete modal visibility

  const [createMotorForm] = Form.useForm();
  const [editMotorForm] = Form.useForm();

  const [createBreakdownForm] = Form.useForm();
  const [editBreakdownForm] = Form.useForm();
  const plantID = localStorage.getItem('plant_id');

  useEffect(() => {
    if (token) {
      fetchMotorTripCounterTrackingDetails();
      fetchBreakdownCounterDetails(); // Fetch Breakdown Counter
      fetchPumps(); // Fetch Pumps
    }
  }, [token]);

  const fetchMotorTripCounterTrackingDetails = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get<MotorTripCounterRecord[]>(`${process.env.REACT_APP_API_URL}/motor-trip-counter/getAllByPlantID/${plant_id}`);
      setMDataSource(response.data);
    } catch (error) {
      message.error('Failed to fetch motor trip counter details');
    }
  };

  const fetchBreakdownCounterDetails = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get<BreakdownCounterRecord[]>(`${process.env.REACT_APP_API_URL}/breakdown-counters/getAllByPlantID/${plant_id}`);
      setBDataSource(response.data);
    } catch (error) {
      message.error('Failed to fetch breakdown counter details');
    }
  };

  const fetchPumps = async () => {
    try {
      const plant_id = localStorage.getItem('plant_id');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pumps`, {
        params: { plant_id }
      });
      setPumpDataSource(response.data);
    } catch (error) {
      message.error('Failed to fetch pumps');
    }
  };

  /** Motor Trip Counter Functions */
  const showMotorCreateModal = () => {
    setIsMotorCreateModalVisible(true);
    createMotorForm.resetFields();
  };

  const showMotorEditModal = (record: MotorTripCounterRecord) => {
    setSelectedMotorRecord(record);
    editMotorForm.setFieldsValue({ ...record, date: dayjs(record.date) });
    setIsMotorEditModalVisible(true);
  };

  const showMotorDeleteModal = (record: MotorTripCounterRecord) => {
    setSelectedMotorRecord(record);
    setIsMotorDeleteModalVisible(true);
  };

  const handleMotorCreate = async (values: any) => {
    console.log('handleMotorCreate')
    try {
      const url = `${process.env.REACT_APP_API_URL}/motor-trip-counter`;
      await axios.post(url, {
        plant_id: plantID,
        pump_id: values.pump_id,
        motor_trip_counter: values.motor_trip_counter.toString(),
        date: values.date.format('YYYY-MM-DD'),
      });
      message.success('Motor Trip Counter created successfully');
      setIsMotorCreateModalVisible(false);
      fetchMotorTripCounterTrackingDetails();
    } catch (error) {
        console.log(error)
      message.error('Failed to create motor trip counter');
    }
  };

  const handleMotorEdit = async (values: any) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/motor-trip-counter/${selectedMotorRecord!.id}`;
      await axios.put(url, {
        plant_id: plantID,
        pump_id: values.pump_id,
        motor_trip_counter: values.motor_trip_counter.toString(),
        date: values.date.format('YYYY-MM-DD'),
      });
      message.success('Motor Trip Counter updated successfully');
      setIsMotorEditModalVisible(false);
      fetchMotorTripCounterTrackingDetails();
    } catch (error) {
        console.log(error)
      message.error('Failed to update motor trip counter');
    }
  };

  const handleMotorDelete = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/motor-trip-counter/${selectedMotorRecord!.id}`;
      await axios.delete(url);
      message.success('Motor Trip Counter deleted successfully');
      setIsMotorDeleteModalVisible(false);
      fetchMotorTripCounterTrackingDetails();
    } catch (error) {
        console.log(error)
      message.error('Failed to delete motor trip counter');
    }
  };

  /** Breakdown Counter Functions */
  const showBreakdownCreateModal = () => {
    setIsBreakdownCreateModalVisible(true);
    createBreakdownForm.resetFields();
  };

  const showBreakdownEditModal = (record: BreakdownCounterRecord) => {
    setSelectedBreakdownRecord(record);
    editBreakdownForm.setFieldsValue({ ...record, date: dayjs(record.date) });
    setIsBreakdownEditModalVisible(true);
  };

  const showBreakdownDeleteModal = (record: BreakdownCounterRecord) => {
    setSelectedBreakdownRecord(record);
    setIsBreakdownDeleteModalVisible(true);
  };

  const handleBreakdownCreate = async (values: any) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/breakdown-counters`;
      await axios.post(url, {
        plant_id: plantID,
        pump_id: values.pump_id,
        duration: values.duration.toString(),
        date: values.date.format('YYYY-MM-DD'),
      });
      message.success('Breakdown Counter created successfully');
      setIsBreakdownCreateModalVisible(false);
      fetchBreakdownCounterDetails();
    } catch (error) {
        console.log(error)
      message.error('Failed to create breakdown counter');
    }
  };

  const handleBreakdownEdit = async (values: any) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/breakdown-counters/${selectedBreakdownRecord!.id}`;
      await axios.put(url, {
        plant_id: plantID,
        pump_id: values.pump_id,
        duration: values.duration.toString(),
        date: values.date.format('YYYY-MM-DD'),
      });
      message.success('Breakdown Counter updated successfully');
      setIsBreakdownEditModalVisible(false);
      fetchBreakdownCounterDetails();
    } catch (error) {
        console.log(error)
      message.error('Failed to update breakdown counter');
    }
  };

  const handleBreakdownDelete = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/breakdown-counters/${selectedBreakdownRecord!.id}`;
      await axios.delete(url);
      message.success('Breakdown Counter deleted successfully');
      setIsBreakdownDeleteModalVisible(false);
      fetchBreakdownCounterDetails();
    } catch (error) {
        console.log(error)
      message.error('Failed to delete breakdown counter');
    }
  };

  /** Modal Cancel Handler */
  const handleCancel = () => {
    setIsMotorCreateModalVisible(false);
    setIsMotorEditModalVisible(false);
    setIsMotorDeleteModalVisible(false);
    setIsBreakdownCreateModalVisible(false);
    setIsBreakdownEditModalVisible(false);
    setIsBreakdownDeleteModalVisible(false);
    createMotorForm.resetFields();
    editMotorForm.resetFields();
    createBreakdownForm.resetFields();
    editBreakdownForm.resetFields();
    setSelectedMotorRecord(null);
    setSelectedBreakdownRecord(null);
  };

  /** Table Columns */
  const motorColumns = [
    { title: 'Application', dataIndex: ['pump', 'application_name'], key: 'application_name' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Motor Trip Counter', dataIndex: 'motor_trip_counter', key: 'motor_trip_counter' },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <>
          <Button onClick={() => showMotorEditModal(record)}>Edit</Button>
          <Button danger onClick={() => showMotorDeleteModal(record)}>Delete</Button>
        </>
      ),
    },
  ];

  const breakdownColumns = [
    { title: 'Application', dataIndex: ['pump', 'application_name'], key: 'application_name' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Duration', dataIndex: 'duration', key: 'duration' },
    {
      title: 'Actions', key: 'actions', render: (_, record) => (
        <>
          <Button onClick={() => showBreakdownEditModal(record)}>Edit</Button>
          <Button danger onClick={() => showBreakdownDeleteModal(record)}>Delete</Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      {/* Motor Trip Counter Section */}
      <Title level={2}>Motor Trip Counter</Title>
      <Button type="primary" onClick={showMotorCreateModal} style={{ background: '#21456A' }}>Create Motor Trip Counter</Button>
      <Table columns={motorColumns} dataSource={mDataSource} />

      {/* Breakdown Counter Section */}
      <Title level={2} style={{ marginTop: '40px' }}>Breakdown Counter</Title>
      <Button type="primary" onClick={showBreakdownCreateModal} style={{ background: '#21456A' }}>Create Breakdown Counter</Button>
      <Table columns={breakdownColumns} dataSource={bDataSource} />

      {/* Motor Trip Counter Modals */}
      <Modal
        title="Create Motor Trip Counter"
        open={isMotorCreateModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={createMotorForm} layout="vertical" onFinish={handleMotorCreate}>
          <Form.Item label="Application" name="pump_id" rules={[{ required: true, message: 'Please select an application' }]}>
            <Select placeholder="Select a pump">
              {pDataSource.map((pump) => (
                <Option key={pump.id} value={pump.id}>{pump.application_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Motor Trip Counter" name="motor_trip_counter" rules={[{ required: true, message: 'Please enter motor trip counter' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Date" name="date" rules={[{ required: true, message: 'Please select date' }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}><Button block onClick={handleCancel} style={{ borderColor: '#2196F3' }}>Cancel</Button></Col>
            <Col span={11}><Button block type="primary" htmlType="submit" style={{ background: '#21456A' }}>Create</Button></Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Edit Motor Trip Counter"
        open={isMotorEditModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={editMotorForm} layout="vertical" onFinish={handleMotorEdit}>
          <Form.Item label="Application" name="pump_id" rules={[{ required: true, message: 'Please select an application' }]}>
            <Select placeholder="Select a pump">
              {pDataSource.map((pump) => (
                <Option key={pump.id} value={pump.id}>{pump.application_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Motor Trip Counter" name="motor_trip_counter" rules={[{ required: true, message: 'Please enter motor trip counter' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Date" name="date" rules={[{ required: true, message: 'Please select date' }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}><Button block onClick={handleCancel} style={{ borderColor: '#2196F3' }}>Cancel</Button></Col>
            <Col span={11}><Button block type="primary" htmlType="submit" style={{ background: '#21456A' }}>Update</Button></Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Delete Motor Trip Counter"
        open={isMotorDeleteModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#FF4D4F', marginBottom: '16px' }} />
          <Title level={4}>Are you sure you want to delete this Motor Trip Counter?</Title>
          <Text>This action cannot be undone.</Text>
          <Row justify="space-between" style={{ marginTop: '16px' }}>
            <Col span={11}><Button block onClick={handleCancel}>Cancel</Button></Col>
            <Col span={11}><Button block type="primary" danger onClick={handleMotorDelete}>Delete</Button></Col>
          </Row>
        </div>
      </Modal>

      {/* Breakdown Counter Modals */}
      <Modal
        title="Create Breakdown Counter"
        open={isBreakdownCreateModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={createBreakdownForm} layout="vertical" onFinish={handleBreakdownCreate}>
          <Form.Item label="Application" name="pump_id" rules={[{ required: true, message: 'Please select an application' }]}>
            <Select placeholder="Select a pump">
              {pDataSource.map((pump) => (
                <Option key={pump.id} value={pump.id}>{pump.application_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Duration (HRS)" name="duration" rules={[{ required: true, message: 'Please enter duration' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Date" name="date" rules={[{ required: true, message: 'Please select date' }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}><Button block onClick={handleCancel} style={{ borderColor: '#2196F3' }}>Cancel</Button></Col>
            <Col span={11}><Button block type="primary" htmlType="submit" style={{ background: '#21456A' }}>Create</Button></Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Edit Breakdown Counter"
        open={isBreakdownEditModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={editBreakdownForm} layout="vertical" onFinish={handleBreakdownEdit}>
          <Form.Item label="Application" name="pump_id" rules={[{ required: true, message: 'Please select an application' }]}>
            <Select placeholder="Select a pump">
              {pDataSource.map((pump) => (
                <Option key={pump.id} value={pump.id}>{pump.application_name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Duration (HRS)" name="duration" rules={[{ required: true, message: 'Please enter duration' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Date" name="date" rules={[{ required: true, message: 'Please select date' }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}><Button block onClick={handleCancel} style={{ borderColor: '#2196F3' }}>Cancel</Button></Col>
            <Col span={11}><Button block type="primary" htmlType="submit" style={{ background: '#21456A' }}>Update</Button></Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Delete Breakdown Counter"
        open={isBreakdownDeleteModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#FF4D4F', marginBottom: '16px' }} />
          <Title level={4}>Are you sure you want to delete this Breakdown Counter?</Title>
          <Text>This action cannot be undone.</Text>
          <Row justify="space-between" style={{ marginTop: '16px' }}>
            <Col span={11}><Button block onClick={handleCancel}>Cancel</Button></Col>
            <Col span={11}><Button block type="primary" danger onClick={handleBreakdownDelete}>Delete</Button></Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default MotorTripAndBreakdownCounter;
